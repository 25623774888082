<template>
  <div class="columns is-flex is-vcentered">
    <div class="column">
      <searchable-list
        ref="sourceList"
        :items.sync="sourceList"
        :selectedItems.sync="sourceSelectedList"
        @updateDoubleClick="updateDoubleClickToTarget"
        placeholder="Type in to filter"
      />
    </div>
    <div class="column is-1">
      <b-button
        rounded
        expanded
        @click="allFilteredToTarget"
        :disabled="sourceList.length===0"
        icon-left="th-list"
        icon-right="chevron-right"
      />
      <b-button
        rounded
        expanded
        @click="toTarget"
        :disabled="sourceSelectedList.length===0"
        icon-left="chevron-right"
      />
      <br />
      <b-button
        rounded
        expanded
        @click="toSource"
        :disabled="targetSelectedList.length===0"
        icon-left="chevron-left"
      />
      <b-button
        rounded
        expanded
        @click="allFilteredToSource"
        :disabled="targetList.length===0"
        icon-right="th-list"
        icon-left="chevron-left"
      />
    </div>
    <div class="column">
      <searchable-list
        ref="targetList"
        :items.sync="targetList"
        :type="type"
        :message="message"
        :selectedItems.sync="targetSelectedList"
        @updateDoubleClick="updateDoubleClickToSource"
        placeholder="Type in to filter"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DualListBox',
  components: {
    'searchable-list': () => import('@/components/ui/SearchableList.vue')
  },
  props: {
    sourceList: {
      type: Array,
      default: function () {
        return []
      }
    },
    targetList: {
      type: Array,
      default: function () {
        return []
      }
    },
    type: {
      type: Object,
      default: null
    },
    message: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      sourceSelectedList: [],
      targetSelectedList: []
    }
  },
  methods: {
    updateDoubleClickToTarget (item) {
      this.$emit('update:targetList', this.targetList.concat(item))
      this.$emit('update:sourceList', this.sourceList.filter(val => !item.includes(val)))
      this.sourceSelectedList = []
    },
    updateDoubleClickToSource (item) {
      this.$emit('update:sourceList', this.sourceList.concat(item))
      this.$emit('update:targetList', this.targetList.filter(val => !item.includes(val)))
      this.targetSelectedList = []
    },
    allFilteredToTarget () {
      this.$emit('update:targetList', this.targetList.concat(this.$refs.sourceList.getFilteredItems()))
      this.$emit('update:sourceList', this.sourceList.filter(val => !this.$refs.sourceList.getFilteredItems().includes(val)))
      this.sourceSelectedList = []
      this.$refs.sourceList.resetInput()
    },
    toTarget () {
      this.$emit('update:targetList', this.targetList.concat(this.sourceSelectedList))
      this.$emit('update:sourceList', this.sourceList.filter(val => !this.sourceSelectedList.includes(val)))
      this.sourceSelectedList = []
      this.$refs.sourceList.resetInput()
    },
    allFilteredToSource () {
      this.$emit('update:sourceList', this.sourceList.concat(this.$refs.targetList.getFilteredItems()))
      this.$emit('update:targetList', this.targetList.filter(val => !this.$refs.targetList.getFilteredItems().includes(val)))
      this.targetSelectedList = []
      this.$refs.targetList.resetInput()
    },
    toSource () {
      this.$emit('update:sourceList', this.sourceList.concat(this.targetSelectedList))
      this.$emit('update:targetList', this.targetList.filter(val => !this.targetSelectedList.includes(val)))
      this.targetSelectedList = []
      this.$refs.targetList.resetInput()
    }
  }
}
</script>
